export const LOGIN_IMAGES = [
  '/v1678221022/assets/login/login-1_vp84jy.jpg?_i=AA',
  '/v1678221017/assets/login/login-2_seohqt.jpg?_i=AA',
  '/v1678221022/assets/login/login-3_tf2cya.jpg?_i=AA',
  '/v1678221018/assets/login/login-4_wmwnzh.jpg?_i=AA',
  '/v1678221025/assets/login/login-5_eqhp6y.jpg?_i=AA',
  '/v1678221014/assets/login/login-6_yiuoi1.jpg?_i=AA',
]

export const SET_RESET_PASSWORD =
  'https://res.cloudinary.com/benchmarkminerals/image/upload/f_auto,q_auto,w_500,h_500/v1678298321/assets/login/set-reset-password_xhowby.jpg?_i=AA'
